<template>
  <div>
    <v-container fluid class="mb-3 px-0">
      <v-row align="center">
        <v-col cols="12" class="mb-0 pb-0">
          <v-card elevation="2" rounded="0" class="mb-1" color="grey lighten-3">
            <v-card-text>
              <v-row align="center">
                <v-col cols="10" class="text-h5 text-uppercase font-weight-light">
                  {{ tLabel("Drop-off") }}
                </v-col>
                <v-col cols="2" class="text-right" v-if="!readOnly && addEnabled">
                  <v-btn outlined color="primary" :title="tLabel('Aggiungi unit/merce')" @click="prepareAdd">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="mt-0 pt-0" v-if="cargoes !== null && cargoes.length > 0">
          <div v-for="cargo in cargoes" :key="cargo.visitId">
            <CargoCard :cargo="cargo" :read-only="readOnly" :edit-position-visible="editPositionVisible" :remove-enabled="removeEnabled" @removeCargoVisit="removeVisit" @cargoSaved="cargoSaved" />            
          </div>
        </v-col>
        <v-col cols="12" class="text-subtitle-1 font-weight-light" v-else> Nessuna merce/unit in consegna. </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="addDialogVisible" fullscreen scrollable persistent>
      <v-card rounded="0" v-if="addDialogVisible">
        <v-card-title class="text-h5 text-uppercase panelheader">
          {{ tLabel("Aggiungi consegna") }}
          <v-spacer />
          <v-icon large @click="closeAddDialog">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <CargoesComposePanel ref="cargoesComposePanel" :cargoes="cargoesToCreate" :cargoType="cargoType" @editing="updateEditing"/>
        </v-card-text>
        <v-card-actions>        
          <v-spacer />
          <v-btn outlined large @click="closeAddDialog" class="mr-2"> <v-icon class="pr-2">mdi-close</v-icon>{{ tLabel("Close") }} </v-btn>
          <v-btn large color="primary" :disabled="cargoesToCreate.length === 0 || editing" @click="add"> <v-icon class="pr-2">mdi-plus</v-icon>{{ tLabel("Aggiungi") }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CargoCard from "@/components/yard/CargoCard.vue";
import CargoesComposePanel from "@/components/yard/CargoesComposePanel";
import ToastMixin from "../../mixins/ToastMixin";

export default {
  name: "DropoffPanel",
  data() {
    return {
      truckTransit: null,
      cargoes: null,
      cargoesToCreate: [],
      cargoType: null,
      addDialogVisible: false,
      editing: false
    };
  },
  props: {
    truckVisitId: {
      type: String,
      required: true,
    },
    editPositionVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    addEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    removeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CargoCard, CargoesComposePanel },
  mixins: [ToastMixin],
  async mounted() {
    this.initItems();
  },
  methods: {
    async initItems() {
      this.truckTransit = await this.$api.yardData.getTruckTransitByVisitId(this.truckVisitId);
      if (this.truckTransit && this.truckTransit !== null && this.truckTransit.cargoes && this.truckTransit.cargoes !== null) {
        this.cargoes = this.truckTransit.cargoes.filter((c) => c.direction === "IN");
      }
      console.log("DROPOFF initItems", this.truckTransit, JSON.stringify(this.cargoes));
    },
    prepareAdd() {
      this.cargoesToCreate = [];    
      this.cargoType = null;
      if (this.cargoes !== null && this.cargoes.length > 0) {
        this.cargoType = this.cargoes[0].cargoType === "goods" ? "GOO" : "UNT";
      }
      this.addDialogVisible = true;        
    },
    async add() {
      try {
        console.log("DROPOFF add", this.cargoesToCreate, JSON.stringify(this.cargoesToCreate));
        if (this.cargoesToCreate.length > 0) {
          await this.$api.yardData.addCargoesToTruckVisit(this.truckVisitId, this.cargoesToCreate);
        }
        this.closeAddDialog();
        this.initItems();
        this.showSuccess("Aggiunta merce/unit consegna avvenuta correttamente.");
      } catch (ex) {
        this.showError("Errore aggiunta merce/unit consegna." || ex);
      }
    },
    async removeVisit(visitId) {
      console.log("removeVisit visitId", visitId);
      if (this.cargoes !== null && this.cargoes.length > 0) {
        let cargoesByVisitId = this.cargoes.filter((i) => i.visitId === visitId);
        if (cargoesByVisitId.length > 0) {
          let handlingId = cargoesByVisitId[0].handlingId;
          console.log("removeVisit handlingId", handlingId);
          try {
            await this.$api.yardData.removeByHandlingId(handlingId);
            this.showSuccess("Rimozione merce/unit consegna avvenuta correttamente.");
            var index = this.cargoes.indexOf(cargoesByVisitId[0]);
            if (index > -1) {
              this.cargoes.splice(index, 1);
            }
          } catch (ex) {
            this.showError("Errore rimozione merce/unit consegna. " + ex.detailMessage);
          }
        }
      }
    },
    async cargoSaved(visitId) {
      console.log("cargoSaved visitId", visitId);
    },
    closeAddDialog() {
      this.addDialogVisible = false;
    },
    updateEditing(editing) {
      this.editing = editing;
    }
  },
};
</script>

<style>
.cursor-vis {
  cursor: pointer;
}
</style>
